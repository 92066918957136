import React, { Component, ChangeEvent } from "react";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { isAuth, getUser, authenticate, logout } from "../utils/auth";
import { connect } from "../utils/atx";
import LoginPage from "./loginPage/loginPage";
import DashboardLayout from "./dashboardLayout/dashboardLayout";
import IncomingDialog from "./dashboardPhone/incomingDialog/incomingDialog";
import CallDialog from "./dashboardPhone/callDialog/callDialog";
import store from "../store";
import { intercept } from "mobx";
import { AlertModal } from "./common/alertModal/alertModal";
import { LogDialog } from "./LogDialog/LogDialog";

const OperatorApp = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  & .dashboard-layout {
    width: 100%;
  }
`;

interface iState {
  user: any;
  isAuth: boolean;
  username: string;
  password: string;
  phoneDialogVisibility: boolean;
}

class App extends Component<{}, iState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      user: null,
      isAuth: isAuth(),
      username: "",
      password: "",
      phoneDialogVisibility: true,
    };

    if (this.state.isAuth) {
      getUser().then(
        (user) => {
          this.setState({
            user,
            isAuth: isAuth(),
          });

          if (this.state.isAuth) {
            connect();
          }
        },
        () => {
          this.setState({
            user: null,
            isAuth: false,
          });

          store.user = null;
        }
      );
    }

    intercept(store, "operatorStatus", (obj) => {
      localStorage.setItem("operator_status", obj.newValue);
      return obj;
    });
  }

  loginUser() {
    if (!this.state.username || !this.state.password) return false;

    authenticate({
      username: this.state.username,
      password: this.state.password,
    }).then((user) => {
      this.setState({
        user,
        isAuth: isAuth(),
      });

      if (this.state.isAuth) {
        connect();
      }
    });
  }

  logoutUser() {
    logout().then(() => {
      this.setState({
        user: null,
        isAuth: isAuth(),
      });
    });
  }

  handleChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const stateObj: any = {};

    stateObj[name] = event.target.value;
    this.setState(stateObj);
  };

  render() {
    return (
      <OperatorApp>
        <AlertModal />
        <IncomingDialog />
        <CallDialog />
        <LogDialog />
        {this.state.isAuth ? (
          <DashboardLayout className="dashboard-layout" user={this.state.user} logout={this.logoutUser.bind(this)} />
        ) : (
          <LoginPage
            login={this.loginUser.bind(this)}
            username={this.state.username}
            password={this.state.password}
            handleChange={this.handleChange.bind(this)}
          />
        )}
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
        />
      </OperatorApp>
    );
  }
}

export default App;
