import React, { Component } from "react";
import { BrowserRouter as Router, Route, NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import DashboardPhone from "../dashboardPhone/dashboardPhone";
import DashboardOperatorsInfo from "../dashboardOperatorsInfo/dashboardOperatorsInfo";
import StatusSwitcher from "./statusSwitcher/statusSwitcher";

import store from "../../store";
import VolumeSlider from "../common/volumeSlider/volumeSlider";
import MicrophoneVolumeSlider from "../common/microphoneVolumeSlider/microphoneVolumeSlider";
import { logout } from "../../utils/auth";

const DRAWER_WIDTH = 60;
const APP_BAR_HEIGHT = 64;

const LayoutContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  & > .layout-drawer {
    flex: 0 0 auto;

    & > div {
      width: ${DRAWER_WIDTH}px;
    }
  }

  & > .layout-appbar {
    width: calc(100% - ${DRAWER_WIDTH}px);
    margin-left: ${DRAWER_WIDTH}px;
  }

  & .toolbar-title {
    flex: 1;
  }
`;

const LogoContainer = styled.div`
  min-height: ${APP_BAR_HEIGHT}px;
  display: flex;
`;

const Logo = styled.img`
  margin: auto;
  max-height: 50px;
`;

const Content = styled.main`
  width: calc(100% - ${DRAWER_WIDTH}px);
  margin-left: ${DRAWER_WIDTH}px;
  padding: 24px;
  margin-top: ${APP_BAR_HEIGHT}px;
  box-sizing: border-box;
  height: calc(100% - ${APP_BAR_HEIGHT}px);
`;

const Wrapper = styled.div`
  display: inline-block;
  margin-right: 15px;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const VoulumeSliders = styled.div`
  min-width: 100px;
  margin-right: 20px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;

  &.active {
    background: rgba(63, 81, 181, 0.2);
    i {
      color: #3f51b5;
    }
  }
`;

const Logs = styled(Button)`
  margin-right: 5px !important;
`;

interface iProps {
  user: any;
  logout: any;
}

class DashboardLayout extends Component<iProps & React.HTMLAttributes<HTMLElement>, {}> {
  openLogs() {
    store.showLogs = true;
  }

  render() {
    const Username = () => <Wrapper>{this.props.user ? this.props.user.fullName : ""}</Wrapper>;

    return (
      <Router>
        <LayoutContainer>
          <AppBar position="absolute" className="layout-appbar">
            <Toolbar>
              <Typography variant="title" color="inherit" noWrap className="toolbar-title">
                АЛЁ...
              </Typography>
              <RightWrapper>
                <VoulumeSliders>
                  <MicrophoneVolumeSlider />
                  <VolumeSlider />
                </VoulumeSliders>
                {store.user && store.user.atxUsername && store.user.atxPassword ? (
                  <Wrapper>
                    <StatusSwitcher />
                  </Wrapper>
                ) : (
                  false
                )}
                <Username />
                <Logs variant="contained" onClick={this.openLogs}>
                  Логи
                </Logs>
                <Button variant="contained" color="secondary" onClick={this.props.logout}>
                  Выйти
                </Button>
              </RightWrapper>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" anchor="left" className="layout-drawer">
            <LogoContainer>
              <Logo src={require("../../assets/img/logo.png")} />
            </LogoContainer>
            <Divider />
            <List>
              <StyledLink exact to="/">
                <ListItem button>
                  <ListItemIcon>
                    <i className="material-icons">local_phone</i>
                  </ListItemIcon>
                </ListItem>
              </StyledLink>
              <StyledLink to="/operators-info">
                <ListItem button>
                  <ListItemIcon>
                    <i className="material-icons">info</i>
                  </ListItemIcon>
                </ListItem>
              </StyledLink>
            </List>
          </Drawer>
          <Content>
            <Route path="/" exact component={DashboardPhone} />
            <Route path="/operators-info" component={DashboardOperatorsInfo} />
          </Content>
        </LayoutContainer>
      </Router>
    );
  }
}

export default DashboardLayout;
