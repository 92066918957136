import { observer } from "mobx-react";
import React, { FC } from "react";
import store from "../../store";
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { toJS } from "mobx";

export const LogDialog: FC = observer(() => {
  const closeDialog = () => {
    store.showLogs = false;
  };

  const JSONLogs = toJS(store.logs);

  const copy = () => {
    navigator.clipboard.writeText(JSON.stringify(JSONLogs));
  };

  return (
    <Dialog onClose={closeDialog} open={store.showLogs}>
      <DialogTitle>Логи</DialogTitle>
      <DialogContent>
        {JSONLogs.map((log) => (
          <>
            <pre>{JSON.stringify(log)}</pre>
            <br />
          </>
        ))}
        <Button onClick={copy}>Скопировать</Button>
      </DialogContent>
    </Dialog>
  );
});
