import { observable, reaction, extendObservable, toJS } from "mobx";

interface iStore {
  currentSession: any;
  incomingDialogVisibility: boolean;
  callDialogVisibility: boolean;
  client: any;
  clientNumber: string;
  clientRegion: any;
  isAutoCall: boolean | undefined;
  enteredNumber: string;
  operatorStatus: boolean;
  statusSwitcherDisabled: boolean;
  callTimerVisibility: boolean;
  localCallList: any;
  volumeLevel: number;
  microphoneVolumeLevel: number;
  user: any;
  alertModalVisibility: boolean;
  alertModalTitle: any;
  alertModalContent: any;
  alertModalActions: any;
  logs: any[];
  showLogs: boolean;
}

const store: iStore = extendObservable(
  {},
  {
    currentSession: {},
    incomingDialogVisibility: false,
    callDialogVisibility: false,
    client: {},
    clientNumber: "",
    clientRegion: null,
    isAutoCall: false,
    enteredNumber: "",
    operatorStatus: localStorage.getItem("operator_status") === "true",
    statusSwitcherDisabled: false,
    callTimerVisibility: false,
    localCallList: observable(JSON.parse(localStorage.getItem("call_list") || "{}")),
    volumeLevel: parseInt(localStorage.getItem("volume_level") || "100", 10),
    microphoneVolumeLevel: parseInt(localStorage.getItem("microphone_volume_level") || "100", 10),
    user: null,
    alertModalVisibility: false,
    alertModalTitle: null,
    alertModalContent: null,
    alertModalActions: null,
    logs: [],
    showLogs: false,
  }
);

reaction(
  () => toJS(store.localCallList),
  (newList) => {
    localStorage.setItem("call_list", JSON.stringify(newList));
  }
);

reaction(
  () => store.user,
  (user) => {
    if (user && !store.localCallList[user.id]) {
      extendObservable(store.localCallList, {
        [store.user.id]: {
          list: [],
        },
      });
    }
  }
);

export default store;
